import {Flex} from "@chakra-ui/react";
import React from "react";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import {graphql} from "gatsby";
import RichContent, {linkTextToDataCnameFormat} from "../components/Collections/RichContent";
import facebook from '../assets/logos/facebook-share.svg';
import twitter from '../assets/logos/twitter-share.svg';
import NewsletterFormView from "../sections/Forms/form-newsletter";
import LeadForm from "../components/Interactive/leadForm";
import leadFormBackgroundImg from '../assets/backgrounds/newsletter.svg';

const Breadcrumbs = ({data}) => {
	return <div className="promo-breadcrumbs">
		<span className="promo-breadcrumb desktop-inline-block"><a href="/">Home</a></span>
		<span className="promo-breadcrumb desktop-inline-block promo-breadcrumb-separator">›</span>
		<span className="promo-breadcrumb mobile-inline-block promo-breadcrumb-separator">‹</span>
		<span className="promo-breadcrumb"><a href="/resources/">Resources</a></span>
		<span className="promo-breadcrumb desktop-inline-block promo-breadcrumb-separator">›</span>
		<span className="promo-breadcrumb desktop-inline-block">{data?.sanityPromoPage?.header?.title}</span>
	</div>;
}

const Title = ({data}) => {
	return <div className="promo-title">{data?.sanityPromoPage?.header?.title}</div>;
}

const UpdatedDate = ({data}) => {
	let updatedAtString = data?.sanityPromoPage?._updatedAt;
	if (!updatedAtString) {
		return null;
	}
	
	let updatedAt = new Date(updatedAtString);
	if (isNaN(updatedAt)) {
		return null;
	}
	
	return <div className="promo-dates-row">
		<span className="promo-date">
			{"Updated " + updatedAt.toLocaleDateString("en-us", {month: "long", day: "numeric", year: "numeric"})}
		 </span>
	</div>;
}

const Authors = ({data}) => {
	let authors = data?.sanityPromoPage?.header?.authors;
	if (!authors || authors.length === 0) {
		return null;
	}
	
	return <div className="promo-authors">By {
		authors.map((author, index) => {
			let commaOptional = "";
			if (index > 0) {
				commaOptional = ", ";
			}
			if (index > 1 && index === authors.length - 1) {
				commaOptional = ", and ";
			}
			return <span className="promo-author" key={author}>{commaOptional + author}</span>
		})
	}</div>;
}

const LikeShareRow = ({data}) => {
	if (typeof window === "undefined") {
		return null;
	}
	
	let currentUrl = encodeURI(window.location.origin + window.location.pathname);
	
	return <div className="promo-share-row">
		<a onClick={() => {
			window.open("https://twitter.com/intent/tweet?url=" + currentUrl, "newwindow", "width=500,height=350");
			return false;
		}} className="promo-share-link"
			data-cname="promo_share_twitter"><img alt="Twitter share" src={twitter}/></a>
		<a onClick={() => {
			window.open("https://www.facebook.com/sharer/sharer.php?&width=100&href=" + currentUrl, "newwindow", "width=500,height=150");
			return false;
		}} className="promo-share-link"
			data-cname="promo_share_facebook"><img alt="Facebook share" src={facebook}/></a>
	</div>;
}

const Summary = ({data}) => {
	let summary = {_rawContent: data?.sanityPromoPage?.header?._rawSummary};
	return <div className="promo-summary"><RichContent data={summary}/></div>;
}

const TableOfContents = ({data}) => {
	let contents = data?.sanityPromoPage?.pageContent?._rawContent;
	if (!contents || contents.length === 0) {
		return null;
	}
	
	let headers = contents.filter(obj => obj.style === "h1" || obj.style === "h2");
	if (!headers || headers.length < 2) {
		return null;
	}
	
	return <div className="promo-toc">
		<div className="promo-toc__title">On this page</div>
		<ul className="promo-toc__links">
			{
				headers.map(header => {
					let text = header.children[0].text;
					if (!text) {
						return null;
					}
					
					let id = linkTextToDataCnameFormat(text);
					return <li key={id} className="promo-toc__link"><a href={"#" + id}>{text}</a></li>;
				})
			}
		</ul>
	</div>;
}

const Content = ({data}) => {
	let contents = {_rawContent: data?.sanityPromoPage?.content?._rawPageContent};
	if (!contents) {
		return null;
	}
	
	return <div className="promo-contents">
		<RichContent data={contents}/>
	</div>;
}

const PromoPageForm = ({data}) => {
	let newsletterType = data?.sanityPromoPage?.content?.formType;
	
	if (newsletterType === "newsletterForm") {
		return <NewsletterFormView/>;
	}
	else if (newsletterType === "leadForm") {
		return (
		 <Flex align='center'
				 justify='center'
				 bgImage={`url(${leadFormBackgroundImg})`}
				 bgRepeat='repeat'
				 bgSize='auto'
				 width="100%">
			 <LeadForm data={{formType: 'homepage', title: 'Ready to Get the Grades, Score, and School?'}}/>
		 </Flex>
		);
	}
	else {
		return null;
	}
}

const PromoPage = ({location, data, pageContext}) => {
	const {header, footer} = pageContext;
	const seo = {
		title: data?.sanityPromoPage?.header?.metaTitle,
		metaDescription: data?.sanityPromoPage?.header?.metaDescription
	};
	
	return (
	 <Layout header={header} footer={footer}>
		 <Seo data={seo} location={location}/>
		 <Flex flexDirection='column' overflowX='hidden' className="promo-page">
			 <Breadcrumbs data={data}/>
			 <Title data={data}/>
			 <UpdatedDate data={data}/>
			 <Authors data={data}/>
			 <LikeShareRow data={data}/>
			 <Summary data={data}/>
			 <TableOfContents data={data}/>
			 <Content data={data}/>
		 </Flex>
		 <PromoPageForm data={data}/>
	 </Layout>
	);
}

export default PromoPage

export const query = graphql`
  query PromoPageQuery ($id: String){
    sanityPromoPage (id: {eq: $id}){
    	_createdAt
    	_updatedAt
     	header{
     		metaTitle
     		metaDescription
     		title
     		authors
      	_rawSummary(resolveReferences: {maxDepth: 50})
     	}
     	content{
     		_rawPageContent(resolveReferences: {maxDepth: 50})
     		formType
     	}
    }
  }
`
